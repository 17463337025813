import React from "react"
import PropTypes from "prop-types"

import StyledTheme from "./StyledTheme"
import Header from "../Header"
import Container from "../generic/Container"
import Social from "../Social"
import Footer from "../Footer"

const Layout = ({ children }) => {
  return (
    <StyledTheme>
      <>
        <Header />
        <Container>
          <main>{children}</main>
          <Social />
          <Footer />
        </Container>
      </>
    </StyledTheme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
