import React from "react"
import "css.gg/icons/css/sun.css"
import "css.gg/icons/css/moon.css"

import useTheme from "../../hooks/useTheme"
import { Button, Container } from "./ThemeToggle.styles"

export default function ThemeToggle() {
  const { theme, toggleTheme } = useTheme()

  return (
    <Button
      onClick={() => toggleTheme()}
      aria-label={`Switch to ${theme === "light" ? "dark" : "light"} theme`}
    >
      <Container activeTheme={theme}>
        <i className="gg-sun"></i>
        <i className="gg-moon"></i>
      </Container>
    </Button>
  )
}
