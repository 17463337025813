import React from "react"

import { Wrapper } from "./Footer.styles"

const Footer = () => (
  <Wrapper>
    <p>I'm James © {new Date().getFullYear()}</p>
  </Wrapper>
)

export default Footer
