import styled, { css } from "styled-components"
import { GatsbyLink } from "../generic/Generic.styles"

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${(props) => props.theme.container.width};
  min-height: 50px;
  margin: 0 auto;
  padding: 2rem 5%;
  border-bottom: ${(props) =>
    !props.homePage && css`1px solid ${props.theme.colours.grey.light}`};
`

export const Heading = styled.h1`
  font-size: 1.75rem;
  text-transform: uppercase;
`

export const Link = styled(GatsbyLink)`
  color: ${(props) => props.theme.colours.primary};
`
