import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ListItem, Link } from "./Social.styles"
import Icon from "../Icon"

const SocialListItem = ({ name, link }) => {
  const data = useStaticQuery(graphql`
    query SocialItemQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ListItem>
      <Link href={link} title={`${data.site.siteMetadata.title} on ${name}`}>
        <Icon name={name.toLowerCase()} size={3} />
      </Link>
    </ListItem>
  )
}

export default SocialListItem
