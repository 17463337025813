import styled, { css } from "styled-components"

export const Button = styled.button`
  position: relative;
  height: 30px;
  width: 70px;
  background-color: ${(p) => p.theme.colours.secondary};
  border: 2px solid ${(p) => p.theme.colours.primary};
  border-radius: 500px;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-color: ${(p) => p.theme.colours.primary};
    border-radius: 50%;
    transition: 0.5s;
    z-index: 1;

    ${(p) =>
      p.activeTheme === "dark" &&
      css`
        transform: translateX(39px);
      `}
  }

  i {
    color: ${(p) => p.theme.colours.primary};
    transition: all 0.3s ease-in;
  }

  i:first-of-type {
    transform: translateX(5px);
  }

  i:last-of-type {
    margin-right: 5px;
  }

  i.gg-sun {
    ${(p) =>
      p.activeTheme === "light" &&
      css`
        transform: translateY(30px);
      `}
  }

  i.gg-moon {
    ${(p) =>
      p.activeTheme === "dark" &&
      css`
        transform: translateY(-30px);
      `}
  }
`
