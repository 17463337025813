import { css, createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const Global = css`
  html,
  body {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: ${(props) => props.theme.colours.primary};
    background-color: ${(props) => props.theme.colours.secondary};
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
  }
`

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${Global}
`

export default GlobalStyle
