import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const link = css`
  color: ${(props) => props.theme.colours.blue.medium};
  text-decoration: none;
  transition: 0.2s ease-in;

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colours.blue.dark};
  }
`

export const InternalLink = styled.a`
  ${link}
`

export const GatsbyLink = styled(Link)`
  ${link}
`
