import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Header as Container, Heading, Link } from "./Header.styles"
import ThemeToggle from "../ThemeToggle"

const Header = () => {
  const data = useStaticQuery(query)
  const isHomePage =
    typeof window !== "undefined" && window.location.pathname === "/"

  return (
    <Container homePage={isHomePage}>
      <Heading>
        {!isHomePage && <Link to="/">{data.site.siteMetadata.title}</Link>}
      </Heading>
      <ThemeToggle />
    </Container>
  )
}

export default Header

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
