import styled from "styled-components"

export const Svg = styled.svg.attrs((props) => ({
  height: `${props.size}em`,
  width: `${props.size}em`,
}))`
  fill: ${(props) =>
    props.colour
      ? props.theme.colours[props.colour].medium
      : props.theme.colours.primary};
  transition: 0.2s ease-in;

  a:hover &,
  a:focus &,
  a:active & {
    fill: ${(props) =>
      props.colour
        ? props.theme.colours[props.colour].dark
        : props.theme.colours.blue.dark};
  }
`
