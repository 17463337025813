import styled from "styled-components"

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

export const ListItem = styled.li`
  margin: 0 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const Link = styled.a``
