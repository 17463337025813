import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import GlobalStyle from "../../styled/global"
import { ThemeContextProvider } from "../../context/ThemeContext"
import useTheme from "../../hooks/useTheme"
import { lightTheme, darkTheme } from "../../styled/theme"

const StyledThemeProvider = ({ children }) => {
  const { theme } = useTheme()

  const themePreference = theme === "light" ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={themePreference}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

const StyledTheme = ({ children }) => {
  return (
    <ThemeContextProvider>
      <StyledThemeProvider>{children}</StyledThemeProvider>
    </ThemeContextProvider>
  )
}

StyledTheme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StyledTheme
