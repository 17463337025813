const theme = {
  container: {
    width: "960px",
  },
}

// Shared colours across all themes
const colours = {
  grey: {
    light: "#E0E0E0",
    medium: "#BDBDBD",
    dark: "#757575",
  },
  blue: {
    medium: "#3498db",
    dark: "#217dbb",
  },
  white: "#ffffff",
  black: "#333333",
}

export const lightTheme = {
  colours: {
    primary: "#333333",
    secondary: "#ffffff",
    ...colours,
  },
  ...theme,
}

export const darkTheme = {
  colours: {
    primary: "#ffffff",
    secondary: "#2d3748",
    ...colours,
  },
  ...theme,
}
