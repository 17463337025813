import React from "react"

import { Section } from "./Social.styles"
import SocialList from "./SocialList"

const Social = () => {
  return (
    <Section>
      <SocialList />
    </Section>
  )
}

export default Social
