import { useContext, useEffect } from "react"

import { ThemeContext } from "../context/ThemeContext"

export const useTheme = () => {
  const [theme, setTheme] = useContext(ThemeContext)

  // Update local storage & context state with theme preference
  const setThemePreference = (preference) => {
    window.localStorage.setItem("theme", preference)
    setTheme(preference)
  }

  const toggleTheme = () => {
    if (theme === "light") {
      setThemePreference("dark")
    } else {
      setThemePreference("light")
    }
  }

  // Run just once when we first mount.
  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme")

    if (localTheme) {
      setThemePreference(localTheme)
      return
    }

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setThemePreference("dark")
    } else {
      setThemePreference(theme)
    }
  }, [])

  return { theme, toggleTheme }
}

export default useTheme
