/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"
import Layout from "./src/components/layouts/Layout"

export const wrapPageElement = ({ element, props }) => {
  return (
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    <Layout {...props}>{element}</Layout>
  )
}
