import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { List } from "./Social.styles"
import SocialListItem from "./SocialListItem"

const renderList = (item, index) => {
  return <SocialListItem name={item.name} link={item.link} key={index} />
}

const SocialList = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            name
            link
          }
        }
      }
    }
  `)

  return <List>{data.site.siteMetadata.social.map(renderList)}</List>
}

export default SocialList
